/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/
body {
	padding-top: 0px;
  /*margin-left:500px;
  margin-right:100px;*/
}

.btn-headline-text{
  color:rgb(13, 14, 13);
  font-size: 22px;
  text-decoration: none
}

.btn-light-image-and-link{
  /*color:rgb(13, 14, 13);  */
  text-decoration: none
}

.btn-light-image-and-link:hover{
	/*border-color: #F5F3F9;
	text-decoration: underline !important;
	color:rgb(241, 241, 247);*/
	font-weight : bold ;
}

.btn-light-whole-square{
  /*color:rgb(219, 15, 15);  */
  padding-bottom: 150px;
  height: 550px;
}

/*.btn-primary {
	background-color: #F5F3F9 !important;
	text-decoration: underline !important;
	border-color: #F5F3F9;
	color:black;
	font-weight : bold ;
}*/



/*.btn-light{
  color:rgb(8, 8, 8);
  font-size: 25px;
}*/

.container-fluid{
  margin-left: 0px;
  margin-right: 0px;
}

.img-fluid {
  width: 600px;
  height: 400px;
  float: left;
  margin: 0px;
  vertical-align:top
}

.navbar-brand{
  font-size: 45px;  
}